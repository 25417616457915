import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import BaseTopNav from '@/components/base/baseTopNav';
import { useStrignStorage } from '@/hooks/useStorage';
import { cn } from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModel } from '@umijs/max';

interface Timezone {
  name: string;
  offset: string;
  dayjsOffset: string;
  KLineChartTimezone: string; // echarts 所需的时区
}

const TimezoneSelector: React.FC = () => {  
  const { t } = useTranslation();
  // 获取时区列表数据
  const {timezones ,klineTimeZone,setKlineTimeZone} =useModel('system')

  // const [selectedTimezone, setSelectedTimezone] = useStrignStorage({
  //   key: 'selectedTimezoneName',
  //   defaultValue: t("{{name}}",{name:selectedTimezoneName}),
  // });

  const onClickSelectedTimezone = (value: any, dayjsOffset: any, chartTimezone:string) => {
    window.localStorage.setItem('selectedTimezone', dayjsOffset);
    // setSelectedTimezone(chartTimezone);
    // // 修改系统的时区选择
    setKlineTimeZone(chartTimezone);
  };
  
  return (
    <div className="">
      <BaseTopNav title={t('时区选择')} />

      <div className="px-4">
        {t('当前时间')}
        <span className="ml-3">
          {RenderUtil.formatDate(new Date().getTime(),"YYYY-MM-DD HH:mm:ss")}
        </span>
      </div>

      <ul className="list-none px-4 pt-3">
        {timezones.map((timezone) => (
          <li
            key={timezone.KLineChartTimezone}
            className={cn(
              {
                'bg-blue-500 g-primary text-titleColor':
                klineTimeZone === timezone?.KLineChartTimezone,
                'bg-backgroundAuxiliaryColor text-black':
                klineTimeZone !== timezone?.KLineChartTimezone,
              },
              'p-2 cursor-pointer rounded-md flex items-center justify-between',
            )}
            onClick={() =>
              onClickSelectedTimezone(timezone.name, timezone.dayjsOffset,timezone.KLineChartTimezone)
            }
          >
            {`(${timezone.offset}) ${timezone.name}`}

            {klineTimeZone === timezone?.KLineChartTimezone && (
              <IconParkSolidCorrect className="text-primary text-xl ml-5" />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TimezoneSelector;
